import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Page, PageOptions } from '../../../shared/types/page.type';
import { Order } from '../types/order.type';
import { CryptoCurrency } from '../types/crypto-currency.type';
import { AccountCounter, SavingsAccount } from '../types/savings-account.type';
import { Benefit } from '../types/benefit.type';
import { CancelOrderDto, FinalizeeOrderDto } from '../dtos/order.dto';
import { User } from '../types/user.type';
import { PaymentDto } from '../dtos/payment.dto';
import { map } from 'rxjs/operators';
import {
  DepositOverview,
  FiscalOverview,
  PayedTax,
  Tax,
  TaxDetalization,
} from '../types/tax.type';
import { FrozenCheckHistory, Frozens } from '../types/frozen.type';
import { Bank } from '../types/bank';
import { ENamespaceStatus } from '../enum/namespace-status.enum';
import {
  OrganisationScanEmeraldHistoryResult,
  PersonScanEmeraldHistoryResult,
} from '../types/emerald-result.type';
import { OrderType } from '../enum/order-type.enum';

@Injectable({
  providedIn: 'root',
})
export class LivretService {
  constructor(private http: HttpClient) {}

  getSavingsAccount(userId: string): Observable<SavingsAccount> {
    return this.http
      .get<SavingsAccount>(
        `${environment.baseUrl}/exchange/savings-account/${userId}`
      )
      .pipe(
        map((account) => {
          return {
            ...account,
            balance: account.balance.sort((a, b) =>
              a.name.localeCompare(b.name)
            ),
          };
        })
      );
  }

  closeAccount(userId: string): Observable<Partial<SavingsAccount>> {
    return this.http.patch<Partial<SavingsAccount>>(
      `${environment.baseUrl}/exchange/accounts/close`,
      { userId }
    );
  }

  scoreUser(userId: string, state: boolean): Observable<SavingsAccount> {
    return this.http.patch<SavingsAccount>(
      `${environment.baseUrl}/exchange/user/score`,
      { userId, state }
    );
  }

  updateBalance(id: string, quantityToAdd: number): Observable<SavingsAccount> {
    return this.http.patch<SavingsAccount>(
      `${environment.baseUrl}/exchange/accounts/balance`,
      { id, quantityToAdd }
    );
  }

  getTechnicalAccount(): Observable<SavingsAccount> {
    return this.http
      .get<SavingsAccount>(`${environment.baseUrl}/exchange/technical-account`)
      .pipe(
        map((account) => {
          return {
            ...account,
            balance: account.balance.sort((a, b) =>
              a.name.localeCompare(b.name)
            ),
          };
        })
      );
  }

  getTotalNumberOfAccounts(): Observable<AccountCounter> {
    return this.http.get<AccountCounter>(
      `${environment.baseUrl}/exchange/accounts/count`
    );
  }

  getAccountsPage(page: PageOptions): Observable<Page<SavingsAccount>> {
    if (page.type === 'ALL') {
      return this.http.get<Page<SavingsAccount>>(
        `${environment.baseUrl}/exchange/accounts/page?page=${page.page}&limit=${page.limit}&filter=${page.filter}&sortDirection=${page.sortDirection}`
      );
    }

    if (page.type === 'PENDING') {
      return this.http.get<Page<SavingsAccount>>(
        `${environment.baseUrl}/exchange/accounts/pending?page=${page.page}&limit=${page.limit}&filter=${page.filter}&sortDirection=${page.sortDirection}`
      );
    }
    if (page.type === 'CLOSED') {
      return this.http.get<Page<SavingsAccount>>(
        `${environment.baseUrl}/exchange/accounts/closed?page=${page.page}&limit=${page.limit}&filter=${page.filter}&sortDirection=${page.sortDirection}`
      );
    }
  }

  activateAccount(userId: string): Observable<SavingsAccount> {
    return this.http.post<SavingsAccount>(
      `${environment.baseUrl}/exchange/accounts/activate`,
      { userId }
    );
  }

  rejectAccount(userId: string): Observable<SavingsAccount> {
    return this.http.post<SavingsAccount>(
      `${environment.baseUrl}/exchange/accounts/reject`,
      { userId }
    );
  }

  getGrossBenefits(): Observable<Benefit[]> {
    return this.http.get<Benefit[]>(
      `${environment.baseUrl}/exchange/benefit/gross`
    );
  }

  calculateGrossBenefits(): Observable<{ status: boolean }> {
    return this.http.patch<{ status: boolean }>(
      `${environment.baseUrl}/exchange/benefit/gross`,
      null
    );
  }

  addBenefit(dto: Partial<Benefit>): Observable<Benefit[]> {
    return this.http.post<Benefit[]>(
      `${environment.baseUrl}/exchange/benefit`,
      dto
    );
  }

  getOrdersPage(options: PageOptions): Observable<Page<Order>> {
    const params = new HttpParams({
      fromObject: {
        ...options,
      },
    });

    return this.http.get<Page<Order>>(
      `${environment.baseUrl}/exchange/orders/page`,
      { params }
    );
  }

  getOrdersByUserId(userId: string): Observable<Order[]> {
    return this.http.get<Order[]>(
      `${environment.baseUrl}/exchange/orders/user/${userId}`
    );
  }

  cancelOrder(dto: CancelOrderDto): Observable<Order> {
    return this.http.patch<Order>(
      `${environment.baseUrl}/exchange/orders/cancel`,
      dto
    );
  }

  finalizeOrder(dto: FinalizeeOrderDto): Observable<Order> {
    return this.http.patch<Order>(
      `${environment.baseUrl}/exchange/orders/finalize`,
      dto
    );
  }

  createOrder(dto: {
    transactionId: string;
    bookingId: string;
  }): Observable<Order> {
    return this.http.post<Order>(
      `${environment.baseUrl}/exchange/orders/create-manual`,
      dto
    );
  }

  createWithdrawalOrder(dto: {
    userId: string;
    amount: number;
    type: OrderType;
  }): Observable<Order> {
    return this.http.post<Order>(
      `${environment.baseUrl}/exchange/orders/withdrawal/manual`,
      dto
    );
  }

  getCryptoCurrencies(): Observable<CryptoCurrency[]> {
    return this.http.get<CryptoCurrency[]>(
      `${environment.baseUrl}/exchange/supported-crypto`
    );
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(`${environment.baseUrl}/exchange/user/${id}`);
  }

  getUsers(options: PageOptions): Observable<Page<User>> {
    const params = new HttpParams({
      fromObject: {
        ...options,
      },
    });
    return this.http.get<Page<User>>(`${environment.baseUrl}/exchange/users`, {
      params,
    });
  }

  getUsersWithExceededLimit(): Observable<User[]> {
    return this.http.get<User[]>(
      `${environment.baseUrl}/exchange/user/limits/exceeded`
    );
  }

  getUserLimit(
    userId: string
  ): Observable<
    { type: string; status: string; limit: number; deposit: number }[]
  > {
    return this.http.get<
      { type: string; status: string; limit: number; deposit: number }[]
    >(`${environment.baseUrl}/exchange/user/limits/id/${userId}`);
  }

  addPayment(dto: PaymentDto): Observable<Order> {
    return this.http.post<Order>(
      `${environment.baseUrl}/exchange/payment`,
      dto
    );
  }

  changeRate(dto: {
    userId: string;
    rate: number;
  }): Observable<SavingsAccount> {
    return this.http.post<SavingsAccount>(
      `${environment.baseUrl}/exchange/rate`,
      dto
    );
  }

  changeTax(dto: { userId: string; tax: number }): Observable<SavingsAccount> {
    return this.http.post<SavingsAccount>(
      `${environment.baseUrl}/exchange/tax`,
      dto
    );
  }

  addIban(dto: { userId: string; iban: string }): Observable<User> {
    return this.http.post<User>(
      `${environment.baseUrl}/exchange/user/iban`,
      dto
    );
  }

  getIps(userId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}/exchange/user/ips/id/${userId}`
    );
  }

  getTaxDetails(userId: string, orderId: string): Observable<Tax> {
    return this.http.get<Tax>(
      `${environment.baseUrl}/exchange/taxes/user/${userId}/order/${orderId}`
    );
  }

  getPayedTaxes(userId: string): Observable<PayedTax> {
    return this.http.get<PayedTax>(
      `${environment.baseUrl}/exchange/taxes/payed/user/${userId}`
    );
  }

  getTaxDetalization(userId: string): Observable<Tax[]> {
    return this.http.get<Tax[]>(
      `${environment.baseUrl}/exchange/taxes/detalization/user/${userId}`
    );
  }

  getFiscalOverview(userId: string): Observable<FiscalOverview> {
    return this.http.get<FiscalOverview>(
      `${environment.baseUrl}/exchange/taxes/fiscal/user/${userId}`
    );
  }

  getDepositOverview(userId: string): Observable<DepositOverview[]> {
    return this.http.get<DepositOverview[]>(
      `${environment.baseUrl}/exchange/taxes/deposits/user/${userId}`
    );
  }

  getFrozens(): Observable<Frozens[]> {
    return this.http.get<Frozens[]>(
      `${environment.baseUrl}/exchange/user/frozen-assets`
    );
  }

  getBanks(): Observable<Bank[]> {
    return this.http.get<Bank[]>(`${environment.baseUrl}/exchange/user/banks`);
  }

  getFrozensHistory(): Observable<FrozenCheckHistory[]> {
    return this.http.get<FrozenCheckHistory[]>(
      `${environment.baseUrl}/exchange/user/frozen-assets/history`
    );
  }

  deleteFrozens(id: string): Observable<{ status: boolean }> {
    return this.http.delete<{ status: boolean }>(
      `${environment.baseUrl}/exchange/user/frozen-assets?id=${id}`
    );
  }

  /* PEP / SANCTIONS */
  updatePepStatus(
    userId: string,
    pepId: string,
    status: ENamespaceStatus
  ): Observable<SavingsAccount> {
    return this.http.patch<SavingsAccount>(
      `${environment.baseUrl}/exchange/pep/user/${userId}/pep/${pepId}`,
      { status }
    );
  }

  addPepComment(
    userId: string,
    pepId: string,
    comment: string
  ): Observable<SavingsAccount> {
    return this.http.patch<SavingsAccount>(
      `${environment.baseUrl}/exchange/pep/comment/user/${userId}/pep/${pepId}`,
      { comment }
    );
  }

  getPersonPepDetails(
    scanId: string
  ): Observable<PersonScanEmeraldHistoryResult> {
    return this.http.get<PersonScanEmeraldHistoryResult>(
      `${environment.baseUrl}/exchange/pep-person/details/${scanId}`
    );
  }

  getOrganizationPepDetails(
    scanId: string
  ): Observable<OrganisationScanEmeraldHistoryResult> {
    return this.http.get<OrganisationScanEmeraldHistoryResult>(
      `${environment.baseUrl}/exchange/pep-organization/details/${scanId}`
    );
  }
}
