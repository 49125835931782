import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Order } from '../types/order.type';
import { LivretActions } from './action-types';
import { AuthActions } from '../../auth/store/action-types';
import { SavingsAccount } from '../types/savings-account.type';
import { CryptoCurrency } from '../types/crypto-currency.type';
import { Benefit } from '../types/benefit.type';
import { User } from '../types/user.type';
import { AccountStatus } from '../enum/status.enum';
import { FrozenCheckHistory, Frozens } from '../types/frozen.type';
import { Bank } from '../types/bank';

export const livretFeatureKey = 'livret';

export interface UsersEntityState extends EntityState<User> {
  total?: number;
  updated: boolean;
}

export interface FrozensEntityState extends EntityState<Frozens> {
  total?: number;
}

export interface OrdersEntityState extends EntityState<Order> {
  total?: number;
  updated: boolean;
}

export interface PendingAccountEntityState extends EntityState<SavingsAccount> {
  total?: number;
  loading: boolean;
}

export interface ClosedAccountEntityState extends EntityState<SavingsAccount> {
  total?: number;
  loading: boolean;
}

export interface SupportedCurrenciesEntityState
  extends EntityState<CryptoCurrency> {
  total?: number;
  loading: boolean;
}

export interface GrossBenefitsEntityState extends EntityState<Benefit> {
  total?: number;
  loading: boolean;
}

export interface LivretState {
  orders: OrdersEntityState;
  users: UsersEntityState;
  frozens: FrozensEntityState;
  frozensHistroy: FrozenCheckHistory[];
  banks: Bank[];
  pendingAccounts: PendingAccountEntityState;
  closedAccounts: ClosedAccountEntityState;
  loaded: boolean;
  loading: boolean;
  account: {
    account: SavingsAccount;
    loading: boolean;
  };
  cryptoCurrency: SupportedCurrenciesEntityState;
  grossBenefits: GrossBenefitsEntityState;
  paginator: {
    filter: string;
    sortDirection: 'ASC' | 'DESC';
    pageIndex: number;
    pageSize: number;
  };
  rateUpdated: boolean;
}

export const adapterOrders = createEntityAdapter<Order>({
  selectId: (order) => order.id,
});

export const adapterFrozens = createEntityAdapter<Frozens>({
  selectId: (frozen) => frozen.id,
});

export const adapterUsers = createEntityAdapter<User>({
  selectId: (user) => user.id,
});

export const adapterPendingAccounts = createEntityAdapter<SavingsAccount>({
  selectId: (order) => order.id,
});

export const adaptedCurrencies = createEntityAdapter<CryptoCurrency>({
  selectId: (curr) => curr.id,
});

export const adaptedBenefits = createEntityAdapter<Benefit>({
  selectId: (curr) => curr.id,
});

const ordersInitialState: OrdersEntityState = adapterOrders.getInitialState({
  total: 0,
  updated: false,
});

const frozensInitialState: FrozensEntityState = adapterFrozens.getInitialState({
  total: 0,
});

const usersInitialState: UsersEntityState = adapterUsers.getInitialState({
  total: 0,
  updated: false,
});

const pendingAccountsInitialState: PendingAccountEntityState = adapterPendingAccounts.getInitialState(
  {
    total: 0,
    loading: false,
  }
);

const closedAccountsInitialState: PendingAccountEntityState = adapterPendingAccounts.getInitialState(
  {
    total: 0,
    loading: false,
  }
);

const currencyInitialState: SupportedCurrenciesEntityState = adaptedCurrencies.getInitialState(
  {
    total: 0,
    loading: false,
  }
);

const grossBenefitsInitialState: GrossBenefitsEntityState = adaptedBenefits.getInitialState(
  {
    total: 0,
    loading: false,
  }
);

export const livretInitialState: LivretState = {
  orders: ordersInitialState,
  users: usersInitialState,
  frozens: frozensInitialState,
  frozensHistroy: [],
  banks: [],
  pendingAccounts: pendingAccountsInitialState,
  closedAccounts: closedAccountsInitialState,
  loading: false,
  loaded: false,
  account: {
    account: undefined,
    loading: false,
  },
  cryptoCurrency: currencyInitialState,
  grossBenefits: grossBenefitsInitialState,
  paginator: {
    filter: '',
    sortDirection: 'DESC',
    pageIndex: 1,
    pageSize: 20,
  },
  rateUpdated: false,
};

const _livretReducer = createReducer(
  livretInitialState,
  /* ORDERS */
  on(
    LivretActions.clearOrdersTable,
    (state: LivretState): LivretState => {
      return {
        ...state,
        orders: ordersInitialState,
      };
    }
  ),
  on(
    LivretActions.getOrdersPage,
    (state: LivretState): LivretState => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(
    LivretActions.getOrdersPageSuccess,
    (state: LivretState, { page }): LivretState => {
      return {
        ...state,
        loaded: true,
        orders: adapterOrders.upsertMany(page.data, {
          ...state.orders,
          total: page.total,
        }),
      };
    }
  ),
  on(LivretActions.cancelOrder, (state: LivretState) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        updated: false,
      },
    };
  }),
  on(LivretActions.cancelOrderSuccess, (state: LivretState, { order }) => {
    return {
      ...state,
      orders: adapterOrders.updateOne(
        { id: order.id, changes: { ...order } },
        { ...state.orders, updated: true }
      ),
    };
  }),
  on(LivretActions.cancelOrderError, (state: LivretState) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        updated: true,
      },
    };
  }),
  on(LivretActions.finalizeOrder, (state: LivretState) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        updated: false,
      },
    };
  }),
  on(LivretActions.addOrderSuccess, (state: LivretState, { order }) => {
    return {
      ...state,
      orders: adapterOrders.upsertOne(order, {
        ...state.orders,
      }),
    };
  }),
  on(LivretActions.finalizeOrderSuccess, (state: LivretState, { order }) => {
    return {
      ...state,
      orders: adapterOrders.updateOne(
        { id: order.id, changes: { ...order } },
        { ...state.orders, updated: true }
      ),
    };
  }),
  on(LivretActions.finalizeOrderError, (state: LivretState) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        updated: true,
      },
    };
  }),
  on(
    LivretActions.setPaginator,
    (state: LivretState, { options }): LivretState => {
      return {
        ...state,
        paginator: {
          filter: options.filter,
          sortDirection: options.sortDirection,
          pageIndex: +options.page,
          pageSize: +options.limit,
        },
      };
    }
  ),
  on(
    LivretActions.resetPaginator,
    (state: LivretState): LivretState => {
      return {
        ...state,
        paginator: {
          filter: '',
          sortDirection: 'DESC',
          pageIndex: 1,
          pageSize: 20,
        },
      };
    }
  ),
  /* ACCOUNT */
  on(
    LivretActions.clearAccount,
    (state: LivretState): LivretState => {
      return {
        ...state,
        account: {
          account: undefined,
          loading: false,
        },
      };
    }
  ),
  on(LivretActions.getAccount, (state: LivretState) => {
    return {
      ...state,
      account: {
        ...state.account,
        loading: true,
      },
    };
  }),
  on(
    LivretActions.getAccountSuccess,
    LivretActions.changeRateSuccess,
    LivretActions.changeTaxSuccess,
    (state: LivretState, { account }) => {
      return {
        ...state,
        account: {
          account,
          loading: false,
        },
      };
    }
  ),
  on(LivretActions.getTechAccount, (state: LivretState) => {
    return {
      ...state,
      account: {
        ...state.account,
        loading: true,
      },
    };
  }),
  on(LivretActions.getTechAccountSuccess, (state: LivretState, { account }) => {
    return {
      ...state,
      account: {
        account,
        loading: false,
      },
      orders: {
        ...state.orders,
        updated: false,
      },
    };
  }),
  on(LivretActions.updateBalanceSuccess, (state: LivretState, { account }) => {
    return {
      ...state,
      account: {
        account,
        loading: false,
      },
    };
  }),
  on(LivretActions.closeAccountSuccess, (state: LivretState, { account }) => {
    return {
      ...state,
      pendingAccounts: adapterPendingAccounts.updateOne(
        {
          id: account.id,
          changes: { ...account, status: AccountStatus.CLOSED },
        },
        { ...state.pendingAccounts }
      ),
    };
  }),
  on(LivretActions.scoreUserSuccess, (state: LivretState, { account }) => {
    return {
      ...state,
      pendingAccounts: adapterPendingAccounts.updateOne(
        {
          id: account.id,
          changes: { ...account },
        },
        { ...state.pendingAccounts }
      ),
    };
  }),
  on(
    LivretActions.clearPendingAccounts,
    (state: LivretState): LivretState => {
      return {
        ...state,
        pendingAccounts: pendingAccountsInitialState,
      };
    }
  ),
  on(
    LivretActions.clearClosedAccounts,
    (state: LivretState): LivretState => {
      return {
        ...state,
        closedAccounts: closedAccountsInitialState,
      };
    }
  ),
  on(
    LivretActions.getPendingAccounts,
    (state: LivretState): LivretState => {
      return {
        ...state,
        pendingAccounts: {
          ...state.pendingAccounts,
          loading: true,
        },
      };
    }
  ),
  on(
    LivretActions.getClosedAccounts,
    (state: LivretState): LivretState => {
      return {
        ...state,
        closedAccounts: {
          ...state.closedAccounts,
          loading: true,
        },
      };
    }
  ),
  on(
    LivretActions.getAccountsPageSuccess,
    (state: LivretState, { page }): LivretState => {
      return {
        ...state,
        pendingAccounts: adapterPendingAccounts.upsertMany(page.data, {
          ...state.pendingAccounts,
          total: page.total,
          loading: false,
        }),
      };
    }
  ),
  on(
    LivretActions.activateAccountSuccess,
    (state: LivretState, { account }) => {
      return {
        ...state,
        pendingAccounts: adapterPendingAccounts.updateOne(
          { id: account.id, changes: { ...account } },
          { ...state.pendingAccounts }
        ),
      };
    }
  ),
  /* CURRENCIES */
  on(
    LivretActions.clearCurrenciesTable,
    (state: LivretState): LivretState => {
      return {
        ...state,
        cryptoCurrency: currencyInitialState,
      };
    }
  ),
  on(
    LivretActions.getCurrencies,
    (state: LivretState): LivretState => {
      return {
        ...state,
        cryptoCurrency: {
          ...state.cryptoCurrency,
          loading: true,
        },
      };
    }
  ),
  on(
    LivretActions.getCurrenciesSuccess,
    (state: LivretState, { data }): LivretState => {
      return {
        ...state,
        cryptoCurrency: adaptedCurrencies.upsertMany(data, {
          ...state.cryptoCurrency,
          total: data.length,
          loading: false,
        }),
      };
    }
  ),
  /* BENEFITS */
  on(
    LivretActions.clearBenefits,
    (state: LivretState): LivretState => {
      return {
        ...state,
        grossBenefits: grossBenefitsInitialState,
      };
    }
  ),
  on(LivretActions.getGrossBenefits, (state: LivretState) => {
    return {
      ...state,
      grossBenefits: {
        ...state.grossBenefits,
        loading: true,
      },
    };
  }),
  on(
    LivretActions.getGrossBenefitsSuccess,
    (state: LivretState, { benefits }) => {
      return {
        ...state,
        loading: false,
        grossBenefits: adaptedBenefits.upsertMany(benefits, {
          ...state.grossBenefits,
          total: benefits.length,
          loading: false,
        }),
      };
    }
  ),
  on(LivretActions.addBenefit, (state: LivretState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(LivretActions.addPaymentSuccess, (state: LivretState, { order }) => {
    return {
      ...state,
      orders: adapterOrders.updateOne(
        { id: order.id, changes: { ...order } },
        { ...state.orders, updated: true }
      ),
    };
  }),
  // Users
  on(
    LivretActions.clearUsers,
    (state: LivretState): LivretState => {
      return {
        ...state,
        users: usersInitialState,
      };
    }
  ),
  on(
    LivretActions.getUsersExceededSuccess,
    (state: LivretState, { users }): LivretState => {
      return {
        ...state,
        users: adapterUsers.upsertMany(users, {
          ...state.users,
          total: users.length,
        }),
      };
    }
  ),
  on(
    LivretActions.getUsersSuccess,
    (state: LivretState, { page }): LivretState => {
      return {
        ...state,
        users: adapterUsers.upsertMany(page.data, {
          ...state.users,
          total: page.total,
        }),
      };
    }
  ),
  on(
    LivretActions.getFrozensSuccess,
    (state: LivretState, { date }): LivretState => {
      return {
        ...state,
        frozens: adapterFrozens.upsertMany(date, {
          ...state.frozens,
          total: date.length,
        }),
      };
    }
  ),
  on(
    LivretActions.getFrozensHistorySuccess,
    (state: LivretState, { date }): LivretState => {
      return {
        ...state,
        frozensHistroy: date,
      };
    }
  ),
  on(
    LivretActions.deleteFrozensSuccess,
    (state: LivretState, { id }): LivretState => {
      return {
        ...state,
        frozens: adapterFrozens.removeOne(id, {
          ...state.frozens,
          total: state.frozens.total - 1,
        }),
      };
    }
  ),
  on(
    LivretActions.getBanksSuccess,
    (state: LivretState, { banks }): LivretState => {
      return {
        ...state,
        banks,
      };
    }
  ),
  // PEP / Sanctions
  on(
    LivretActions.updatePepStatusSuccess,
    LivretActions.addPepCommentSuccess,
    (state: LivretState, { account }) => {
      return {
        ...state,
        pendingAccounts: adapterPendingAccounts.updateOne(
          { id: account.id, changes: { ...account } },
          { ...state.pendingAccounts }
        ),
      };
    }
  ),
  // logout
  on(AuthActions.logout, (state: LivretState) => {
    return {
      ...state,
      ...livretInitialState,
    };
  })
);

export function livretReducer(state: LivretState | undefined, action: Action) {
  return _livretReducer(state, action);
}
