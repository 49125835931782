import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CashbackTypePipe } from './pipes/cashbackType.pipe';
import { NumericDirective } from './directives/numeric.directive';
import { SaveCloseButtonsComponent } from './components/save-close-buttons/save-close-buttons.component';
import { DeletePageComponent } from './components/delete-page/delete-page.component';
import { IsGrantedDirective } from './role-manager/is-granted.directive';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { MaterialModule } from '../features/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FooterComponent } from './layouts/components/footer/footer.component';
import { EndToEndPipe } from './pipes/end-to-end.pipe';
import { SelectedItemHeaderComponent } from './components/selected-item-header/selected-item-header.component';
import { CustomFormControlInputComponent } from './components/custom-form-control-input/custom-form-control-input.component';
import { CustomDestroyComponent } from './classes/custom-destroy';
import { ControlValueAccessorConnectorComponent } from './classes/control-value-accessor-connector';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { LogoComponent } from './layouts/components/logo/logo.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RolePipe } from './pipes/role.pipe';
import { ToStringPipe } from './pipes/to-string.pipe';
import { ActivePipe } from './pipes/active.pipe';
import { CopyDirective } from './directives/copy.directive';
import { TokenNamePipe } from './pipes/name-token.pipe';
import { PrettyJsonPipe } from './pipes/pretty-json.pipe';
import { CleanJsonPipe } from './pipes/clean-json.pipe';
import { CommonModule } from '@angular/common';
import { TaxDetailsComponent } from '../features/livret-eco/components/tax-details/tax-details.component';

const DECLARATIONS = [
  NumericDirective,
  CashbackTypePipe,
  SaveCloseButtonsComponent,
  DeletePageComponent,
  IsGrantedDirective,
  DatePickerComponent,
  EndToEndPipe,
  RolePipe,
  ToStringPipe,
  ActivePipe,
  FooterComponent,
  SelectedItemHeaderComponent,
  CustomFormControlInputComponent,
  CustomDestroyComponent,
  ControlValueAccessorConnectorComponent,
  TableHeaderComponent,
  LogoComponent,
  CopyDirective,
  TokenNamePipe,
  PrettyJsonPipe,
  CleanJsonPipe,
  TaxDetailsComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  entryComponents: [DeletePageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    NgxFileDropModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,

    ...DECLARATIONS,
  ],
})
export class SharedModule {}
