import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LivretService } from '../../services/livret.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Order } from '../../types/order.type';
import { tap } from 'rxjs/operators';
import { Tax } from '../../types/tax.type';
import { DepositType } from '../../enum/deposit-type.enum';

@Component({
  selector: 'app-tax-details',
  templateUrl: './tax-details.component.html',
  styleUrls: ['./tax-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxDetailsComponent implements OnInit {
  taxDetails$: Observable<Tax>;

  tax$$: BehaviorSubject<number> = new BehaviorSubject(0);
  tax$: Observable<number> = this.tax$$.asObservable();

  nettAmount$$: BehaviorSubject<number> = new BehaviorSubject(0);
  nettAmount$: Observable<number> = this.nettAmount$$.asObservable();

  requestedAmount$$: BehaviorSubject<number> = new BehaviorSubject(0);
  requestedAmount$: Observable<number> = this.requestedAmount$$.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      order: Order;
      tax?: Tax;
    },
    private dialogRef: MatDialogRef<TaxDetailsComponent>,
    private readonly livretService: LivretService
  ) {}

  ngOnInit() {
    this.taxDetails$ = this.generateTax().pipe(
      tap((x) => {
        const records = x.records;
        let amount = 0;
        const deposits = records.filter(
          (x) =>
            (x.depositType === DepositType.DEPOSIT &&
              x.depositProfitsTotalAmount > 0) ||
            x.depositType === DepositType.BONUS
        );

        for (let i = 0; i < deposits.length; i++) {
          const deposit = deposits[i];

          if (deposit.depositType === DepositType.DEPOSIT) {
            amount += deposit.depositProfitsTotalAmount;
          }

          if (deposit.depositType === DepositType.BONUS) {
            amount +=
              deposit.depositAmountUsed + deposit.depositProfitsTotalAmount;
          }
        }
        const withdrawalAmount = this.data.order
          ? this.data.order.amount / 100
          : this.data.tax.records[0].withdrawalAmount;
        const tax = Number((amount * (x.taxRate || 0.3)).toFixed(2));
        const netAmountToPay = withdrawalAmount - tax;
        this.tax$$.next(tax);
        this.requestedAmount$$.next(withdrawalAmount);
        this.nettAmount$$.next(Number(netAmountToPay.toFixed(2)));
        return x;
      })
    );
  }

  private generateTax() {
    if (this.data.tax && !this.data.order) {
      return of(this.data.tax);
    } else {
      return this.livretService.getTaxDetails(
        this.data.order.userId,
        this.data.order.id
      );
    }
  }

  onSubmit(): void {
    this.dialogRef.close();
  }
}
