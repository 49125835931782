import { createAction, props } from '@ngrx/store';
import { Order } from '../types/order.type';
import { Page, PageOptions } from '../../../shared/types/page.type';
import { CryptoCurrency } from '../types/crypto-currency.type';
import { SavingsAccount } from '../types/savings-account.type';
import { Benefit } from '../types/benefit.type';
import { CancelOrderDto, FinalizeeOrderDto } from '../dtos/order.dto';
import { PaymentDto } from '../dtos/payment.dto';
import { User } from '../types/user.type';
import { FrozenCheckHistory, Frozens } from '../types/frozen.type';
import { Bank } from '../types/bank';
import { ENamespaceStatus } from '../enum/namespace-status.enum';
import { OrderType } from '../enum/order-type.enum';

/*

# ORDERS ACTIONS

*/
export const clearOrdersTable = createAction('[LIVRET] Clear orders table');

export const setPaginator = createAction(
  '[LIVRET] Set paginator config',
  props<{
    options: PageOptions;
  }>()
);

export const resetPaginator = createAction('[LIVRET] Reset paginator config');

export const getOrdersPage = createAction(
  '[LIVRET] Get orders page',
  props<{ options: PageOptions }>()
);

export const getOrdersPageSuccess = createAction(
  '[LIVRET] Get orders success',
  props<{ page: Page<Order> }>()
);

export const getOrdersError = createAction(
  '[LIVRET] Get orders error',
  props<{ message: string }>()
);

export const cancelOrder = createAction(
  '[LIVRET] Cancel order',
  props<{ dto: CancelOrderDto }>()
);

export const cancelOrderSuccess = createAction(
  '[LIVRET] Cancel order success',
  props<{ order: Order }>()
);

export const cancelOrderError = createAction(
  '[LIVRET] Cancel order error',
  props<{ message: string }>()
);

export const finalizeOrder = createAction(
  '[LIVRET] Finalize order',
  props<{ dto: FinalizeeOrderDto }>()
);

export const finalizeOrderSuccess = createAction(
  '[LIVRET] Finalize order success',
  props<{ order: Order }>()
);

export const finalizeOrderError = createAction(
  '[LIVRET] Finalize order error',
  props<{ message: string }>()
);

/*

# SUPPORTED TOKENS

*/
export const clearCurrenciesTable = createAction(
  '[LIVRET] Clear currencies table'
);

export const getCurrencies = createAction('[LIVRET] Get currencies');

export const getCurrenciesSuccess = createAction(
  '[LIVRET] Get currencies success',
  props<{ data: CryptoCurrency[] }>()
);

export const getCurrenciesError = createAction(
  '[LIVRET] Get currencies error',
  props<{ message: string }>()
);

/*

# Account

*/
export const clearAccount = createAction('[LIVRET] Clear account');

export const getAccount = createAction(
  '[LIVRET] Get account',
  props<{ userId: string }>()
);

export const getTechAccount = createAction('[LIVRET] Get tech account');

export const getAccountSuccess = createAction(
  '[LIVRET] Get account success',
  props<{ account: SavingsAccount }>()
);

export const getTechAccountSuccess = createAction(
  '[LIVRET] Get tech account success',
  props<{ account: SavingsAccount }>()
);

export const getAccountError = createAction(
  '[LIVRET] Get account error',
  props<{ message: string }>()
);

export const getTechAccountError = createAction(
  '[LIVRET] Get tech account error',
  props<{ message: string }>()
);

export const clearPendingAccounts = createAction(
  '[LIVRET] Clear pending accounts'
);

export const getPendingAccounts = createAction('[LIVRET] Get pending accounts');

export const getPendingAccountsSuccess = createAction(
  '[LIVRET] Get pending accounts success',
  props<{ accounts: SavingsAccount[] }>()
);

export const getPendingAccountsError = createAction(
  '[LIVRET] Get pending accounts error',
  props<{ message: string }>()
);

export const clearClosedAccounts = createAction(
  '[LIVRET] Clear closed accounts'
);

export const getClosedAccounts = createAction('[LIVRET] Get closed accounts');

export const getClosedAccountsSuccess = createAction(
  '[LIVRET] Get closed accounts success',
  props<{ accounts: SavingsAccount[] }>()
);

export const getClosedAccountsError = createAction(
  '[LIVRET] Get closed accounts error',
  props<{ message: string }>()
);

export const activateAccount = createAction(
  '[LIVRET] Activate account',
  props<{ userId: string }>()
);

export const rejectAccount = createAction(
  '[LIVRET] Reject account',
  props<{ userId: string }>()
);

export const activateAccountSuccess = createAction(
  '[LIVRET] Activate account success',
  props<{ account: SavingsAccount }>()
);

export const activateAccountError = createAction(
  '[LIVRET] Activate account error',
  props<{ message: string }>()
);

//
export const scoreUser = createAction(
  '[LIVRET] Score user',
  props<{ userId: string; state: boolean }>()
);

export const scoreUserSuccess = createAction(
  '[LIVRET] Score user success',
  props<{ account: SavingsAccount }>()
);

export const scoreUserError = createAction(
  '[LIVRET] Score user error',
  props<{ message: string }>()
);
//

export const getAccountsPage = createAction(
  '[LIVRET] Get accounts page',
  props<{ options: PageOptions }>()
);

export const getAccountsPageSuccess = createAction(
  '[LIVRET] Get accounts success',
  props<{ page: Page<SavingsAccount> }>()
);

export const getAccountsPageError = createAction(
  '[LIVRET] Get accounts error',
  props<{ message: string }>()
);

export const updateBalance = createAction(
  '[LIVRET] Update tech balance',
  props<{ dto: { id: string; quantityToAdd: number } }>()
);

export const updateBalanceSuccess = createAction(
  '[LIVRET] Update tech balance success',
  props<{ account: SavingsAccount }>()
);

export const updateBalanceError = createAction(
  '[LIVRET] Update tech balance error',
  props<{ message: string }>()
);

export const closeAccount = createAction(
  '[LIVRET] Close account',
  props<{ userId: string }>()
);

export const closeAccountSuccess = createAction(
  '[LIVRET] Close account success',
  props<{ account: Partial<SavingsAccount> }>()
);

export const closeAccountError = createAction(
  '[LIVRET] Close account error',
  props<{ message: string }>()
);

/*

BENEFITS

*/
export const clearBenefits = createAction('[LIVRET] Clear benefits');

export const getGrossBenefits = createAction('[LIVRET] Get gross benefits');

export const getGrossBenefitsSuccess = createAction(
  '[LIVRET] Get gross benefits success',
  props<{ benefits: Benefit[] }>()
);

export const getGrossBenefitsError = createAction(
  '[LIVRET] Get gross benefits error',
  props<{ message: string }>()
);

export const addBenefit = createAction(
  '[LIVRET] Add benefit',
  props<{ dto: Partial<Benefit> }>()
);

export const addBenefitError = createAction(
  '[LIVRET] Add benefit error',
  props<{ message: string }>()
);

/* PAYMENTS */
export const addPayment = createAction(
  '[LIVRET] Add payment',
  props<{ dto: PaymentDto }>()
);

export const addPaymentSuccess = createAction(
  '[LIVRET] Add payment success',
  props<{ order: Order }>()
);

export const addPaymentError = createAction(
  '[LIVRET] Add payment error',
  props<{ message: string }>()
);

/* ADD ORDER MANUALLY */
export const addOrder = createAction(
  '[LIVRET] Add order',
  props<{
    dto: {
      transactionId: string;
      bookingId: string;
      userId: string;
      amount: number;
    };
  }>()
);

export const addWithdrawalOrder = createAction(
  '[LIVRET] Add withdrawal order',
  props<{
    dto: {
      type: OrderType;
      userId: string;
      amount: number;
    };
  }>()
);

export const addOrderSuccess = createAction(
  '[LIVRET] Add order success',
  props<{ order: Order }>()
);

export const addOrderError = createAction(
  '[LIVRET] Add order error',
  props<{ message: string }>()
);

/*
USERS
*/
export const clearUsers = createAction('[LIVRET] Clear users');

export const getUsers = createAction(
  '[LIVRET] Get users',

  props<{ options: PageOptions }>()
);

export const getUsersExceeded = createAction('[LIVRET] Get users exceeded');

export const getUsersExceededSuccess = createAction(
  '[LIVRET] Get users exceeded success',
  props<{ users: User[] }>()
);

export const getUsersSuccess = createAction(
  '[LIVRET] Get users success',
  props<{ page: Page<User> }>()
);

export const getUsersError = createAction(
  '[LIVRET] Get users error',
  props<{ message: string }>()
);

/* RATE */
export const changeRate = createAction(
  '[LIVRET] Change rate',
  props<{ dto: { userId: string; rate: number } }>()
);

export const changeTax = createAction(
  '[LIVRET] Change tax',
  props<{ dto: { userId: string; tax: number } }>()
);

export const changeRateSuccess = createAction(
  '[LIVRET] Change rate success',
  props<{ account: SavingsAccount }>()
);

export const changeTaxSuccess = createAction(
  '[LIVRET] Change tax success',
  props<{ account: SavingsAccount }>()
);

export const changeRateError = createAction(
  '[LIVRET] Change rate error',
  props<{ message: string }>()
);

export const changeTaxError = createAction(
  '[LIVRET] Change tax error',
  props<{ message: string }>()
);

/* IBAN */
export const addIban = createAction(
  '[LIVRET] Add iban',
  props<{ dto: { userId: string; iban: string } }>()
);

export const addIbanSuccess = createAction(
  '[LIVRET] Add iban success',
  props<{ user: User }>()
);

export const addIbaneError = createAction(
  '[LIVRET] Add iban error',
  props<{ message: string }>()
);

/* FROZENS */
export const getFrozens = createAction('[LIVRET] Get frozens');
export const getFrozensHistory = createAction('[LIVRET] Get frozens history');

export const getFrozensSuccess = createAction(
  '[LIVRET] Get frozens history success',
  props<{ date: Frozens[] }>()
);

export const getFrozensHistorySuccess = createAction(
  '[LIVRET] Get frozens success',
  props<{ date: FrozenCheckHistory[] }>()
);

export const getFrozensError = createAction(
  '[LIVRET] Get Frozens error',
  props<{ message: string }>()
);

export const deleteFrozens = createAction(
  '[LIVRET] Delete frozens',
  props<{ id: string }>()
);

export const deleteFrozensSuccess = createAction(
  '[LIVRET] Delete frozens sucess',
  props<{ id: string }>()
);

/* Banks */
export const getBanks = createAction('[LIVRET] Get banks');
export const getBanksSuccess = createAction(
  '[LIVRET] Get banks success',
  props<{ banks: Bank[] }>()
);
export const getBanksError = createAction(
  '[LIVRET] Get banks error',
  props<{ message: string }>()
);

/* PEP / SANCTIONS */
export const approvePep = createAction(
  '[LIVRET] Approve PEP/Sanction',
  props<{ userId: string; pepId: string; status: ENamespaceStatus }>()
);

export const confirmPep = createAction(
  '[LIVRET] Confirm PEP/Sanction',
  props<{ userId: string; pepId: string; status: ENamespaceStatus }>()
);

export const addPepComment = createAction(
  '[LIVRET] Add PEP/Sanction comment',
  props<{ userId: string; pepId: string; comment: string }>()
);

export const updatePepStatusSuccess = createAction(
  '[LIVRET] Update PEP/Sanction status success',
  props<{ account: SavingsAccount }>()
);

export const addPepCommentSuccess = createAction(
  '[LIVRET] Add PEP/Sanction commentn success',
  props<{ account: SavingsAccount }>()
);
