import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-delete-page',
  templateUrl: './delete-page.component.html',
  styleUrls: ['./delete-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeletePageComponent implements OnInit {
  @Output() noAction = new EventEmitter();
  @Output() yesAction = new EventEmitter();

  @Input() name: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      name?: string;
    },
    private dialogRef: MatDialogRef<DeletePageComponent>
  ) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit() {
    this.dialogRef.close(true);
  }
}
